<template>
  <div class="news-container">
    <div class="top">
      <div class="min-size">
        <router-link to="/news" class="back">
          <svg-icon class="icon-arrow-left" icon-class="arrow_left" />
          返回列表
        </router-link>
      </div>
    </div>

    <div class="title-block">
      <div class="min-size">
        <div class="title" v-html="title"></div>
        <div class="create-at">{{pushTime}}</div>
      </div>
    </div>

    <div class="content-block">
      <div class="content">
        <div class="min-size">
          <div v-html="content" style="font-size: 16px;color: #333333;line-height: 32px;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { archivesDetail } from '@/api/api'
const defaultSettings = require('@/settings.js')
export default {
  name: 'ViewNews',
  components: {
  },
  data(){
    return {
      title:'',
      content:'',
      pushTime:'',
    }
  },
  mounted () {
    this.$nextTick(function() {
      // 模板渲染完成后调用接口加载数据
      const id = this.$route.params && this.$route.params.id
      this.getList(id)
    })
  },
  destroyed () {
  },
  created () {
  },
  methods: {
    getList(id) {
      archivesDetail({id:id}).then(response => {
        this.title = response.data.title
        this.content = response.data.content
        this.pushTime = response.data.pushTime
      })
    }
  }
}
</script>

<style lang="less">
.news-container {
  .top {
    padding-top: 88px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    .min-size {
      width: 1200px;

      .back {
        font-size: 14px;
        color: #666666;
        text-decoration: none;
        display: flex;
        align-items: center;

        .icon-arrow-left {
          font-size: 24px;
        }
      }
    }
  }

  .title-block {
    width: 100%;
    height: 220px;
    background: #f3f7fa;
    display: flex;
    justify-content: center;

    .min-size {
      width: 900px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .title {
        p{
          margin: 0px !important;
        }
        font-size: 36px;
        color: #333333;
        line-height: 40px;
      }

      .create-at {
        padding-top: 25px;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
      }
    }
  }

  .content-block {
    display: flex;
    justify-content: center;
    padding: 80px 0;

    .content {
      display: flex;
      justify-content: center;
      width: 1200px;

      .min-size {
        width: 900px;
      }
    }
  }
}
</style>
